/* eslint-disable no-mixed-spaces-and-tabs */
import {
	faPaperPlane,
	faX,
	faSpinner,
	faEdit,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { useAppDispatch, useAppSelector } from "../../../utils/hooks"
import { setUserSettings } from "../../../utils/stores/store"
import { generateCaptions } from "../../../utils/firebase/functions"
import { IconProp } from "@fortawesome/fontawesome-svg-core"
import TextArea from "../../../components/textArea"
import { setImage } from "../../../utils/firebase/api/api"
import Tooltip from "../../../components/tooltip"
import EditGlobalPromptModal from "./editGlobalPromptModal"

interface CaptionGenerationProps {
	setMode: (mode: "main" | "caption") => void
}

const CaptionGeneration = ({ setMode }: CaptionGenerationProps) => {
	const dispatch = useAppDispatch()
	const globalPrompt = useAppSelector(
		(state) => state.root.userSettings.globalPrompt
	)
	const selectedImage = useAppSelector((state) => state.root.selectedImage)
	const [globalPromptText, setGlobalPromptText] = React.useState(globalPrompt)
	const [imagePromptText, setImagePromptText] = React.useState(
		selectedImage.prompt
	)
	const [showGlobalPromptModal, setShowGlobalPromptModal] =
		React.useState(false)

	return (
		<>
			<div
				className="relative h-full w-full flex flex-col items-center justify-center gap-4 p-4 pointer-events-auto cursor-default"
				onClick={(e) => e.stopPropagation()}
			>
				<div
					onClick={(e) => e.stopPropagation()}
					className="relative w-2/3 flex flex-col gap-4"
				>
					<div className="flex flex-row items-center justify-between w-full">
						<TextArea
							className="w-full h-32"
							textAreaClassName="h-full"
							placeholder="Enter prompt here..."
							resize={false}
							value={imagePromptText}
							onChangeDebounced={(e) =>
								setImage({
									id: selectedImage.id,
									prompt: e.target.value,
								})
							}
							onChangeQuick={(e) => setImagePromptText(e.target.value)}
							onSubmit={() => generateCaptions(selectedImage.id)}
						/>
						<div className="flex items-end justify-end h-full mr-[-3rem]">
							{selectedImage.captionsLoading ? (
								<div className="p-3 rounded-md bg-theme-850">
									<svg
										aria-hidden="true"
										className="w-6 h-6 animate-spin fill-theme-400"
										viewBox="0 0 100 101"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
											fill="currentFill"
										/>
									</svg>
								</div>
							) : (
								<button
									className="hover:bg-theme-800 p-3 rounded-md bg-theme-850"
									onClick={() => generateCaptions(selectedImage.id)}
									style={{
										lineHeight: 0,
									}}
								>
									<FontAwesomeIcon
										icon={faPaperPlane as IconProp}
										className="w-5 h-5 pr-1 pt-1"
									/>
								</button>
							)}
						</div>
					</div>
				</div>
				<div
					onClick={(e) => e.stopPropagation()}
					className="h-full w-full flex flex-row flex-wrap items-center justify-between"
				>
					{selectedImage.captionsLoading
						? [null, null, null, null].map((_, index) => (
								<div key={`suggestions ${index}`} className="h-1/2 w-1/2 p-2 ">
									<div className="h-full w-full bg-theme-800 rounded-lg">
										<div className="h-full w-full border-theme-800 rounded-lg border-4 bg-theme-850 animate-pulse"></div>
									</div>
								</div>
						  ))
						: selectedImage.generatedCaptions.map((text, index) => (
								<div key={`suggestions ${index}`} className="h-1/2 w-1/2 p-2">
									<button
										className="h-full w-full border-4 border-theme-800 rounded-lg bg-theme-850 p-4 text-start"
										onClick={() => {
											setImage({ id: selectedImage.id, caption: text })
											setMode("main")
										}}
									>
										<span>{text}</span>
									</button>
								</div>
						  ))}
				</div>
				<button
					className="absolute top-0 right-4 hover:text-red-500 cursor-pointer text-gray-500 p-4 pt-2 mt-[-2rem]"
					onClick={() => setMode("main")}
				>
					<FontAwesomeIcon icon={faX} size="2x" />
				</button>
				<button
					className="absolute top-0 right-20 hover:bg-theme-800 rounded-md bg-theme-850 mt-[-2rem]"
					onClick={() => setShowGlobalPromptModal(true)}
					style={{
						lineHeight: 0,
					}}
				>
					<Tooltip text="Edit Global Prompt">
						<div className="h-full w-full p-3">
							<FontAwesomeIcon icon={faEdit as IconProp} className="w-5 h-5" />
						</div>
					</Tooltip>
				</button>
			</div>
			<EditGlobalPromptModal
				isOpen={showGlobalPromptModal}
				onClose={() => setShowGlobalPromptModal(false)}
			/>
		</>
	)
}

export default CaptionGeneration
