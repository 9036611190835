import { httpsCallable } from "firebase/functions"
import { functions } from "./firebaseConfig"
import store, { setImage, setUser } from '../stores/store'

const generateCaptions = async (imageId: string) => {

    store.dispatch(
        setImage({
            id: imageId,
            captionsLoading: true,
        })
    )

    const callableReturnMessage = httpsCallable(functions, 'generateCaptions')

    console.log("Calling generateCaptions", {
        globalPrompt: store.getState().root.userSettings.globalPrompt || "",
        imagePrompt: store.getState().root.selectedImage.prompt || "",
        imageId: store.getState().root.selectedImage.id || "",
    })
    const result = await callableReturnMessage({
        globalPrompt: store.getState().root.userSettings.globalPrompt || "",
        imagePrompt: store.getState().root.selectedImage.prompt || "",
        imageId: store.getState().root.selectedImage.id || "",
    })

    // console.log(result.data)

    try {
        const captions = (result.data as { captions: string[] }).captions

        store.dispatch(setImage({
            id: imageId,
            generatedCaptions: captions,
            captionsLoading: false
        }))

        return captions
    } catch (e) {
        console.error(e)
        return
    }
}

const connectInstagramUser = async (userId: string, code: string, redirectUri: string): Promise<"success" | "error"> => {
    console.log("Connecting Instagram user", { userId, code, redirectUri })
    const callableReturnMessage = httpsCallable(functions, 'connectInstagramUser')

    const result = await callableReturnMessage({
        userId,
        code,
        redirectUri,
        force: true
    }).catch((e) => {
        console.error(e)
        return "error"
    })

    try {
        if ((result as any)?.data?.message === "success") {
            return "success"
        } else {
            console.error("Error connecting Instagram user", result)
            return "error"
        }
    } catch (e) {
        console.error(e)
        return "error"
    }
}

const getInstagramUserDetails = async (userId: string): Promise<"success" | "error"> => {
    console.log("Getting Instagram user details", { userId })
    const callableReturnMessage = httpsCallable(functions, 'getInstagramUserDetails')

    const result = await callableReturnMessage({
        userId
    }).catch((e) => {
        console.error(e)
        return "error"
    })

    console.log("result", result)
    return "success"
}

const getInstagramUserProfilePhoto = async (userId: string, igAccountId: string): Promise<"success" | "error"> => {
    console.log("Getting Instagram user profile photo", { userId, igAccountId })
    const callableReturnMessage = httpsCallable(functions, 'getInstagramUserProfilePhoto')

    const result = await callableReturnMessage({
        userId,
        igAccountId
    }).catch((e) => {
        console.error(e)
        return "error"
    })

    console.log("result", result)
    return "success"
}

export {
    generateCaptions,
    connectInstagramUser,
    getInstagramUserDetails,
    getInstagramUserProfilePhoto
}