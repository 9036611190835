import React, { useEffect } from "react"
import Switch from "@mui/material/Switch"
import TagSettings from "./tagSettings"
import { useAppSelector } from "../../utils/hooks"
import { toast } from "react-toastify"
import { changeEmail, changePassword } from "../../utils/firebase/api/auth"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRefresh } from "@fortawesome/free-solid-svg-icons"
import { FaInstagramSquare } from "react-icons/fa"
import InstagramSettings from "./instagramSettings"
import { getInstagramUserDetails } from "../../utils/firebase/functions"
import getAllIgAccounts from "../../utils/firebase/api/getAllIgAccounts"

const SettingsPage: React.FC = () => {
	const user = useAppSelector((state) => state.root.user)
	const [newEmail, setNewEmail] = React.useState(user?.email)
	const [password, setPassword] = React.useState("")
	const [confirmPassword, setConfirmPassword] = React.useState("")

	useEffect(() => {
		getAllIgAccounts()
	}, [])

	useEffect(() => {
		setNewEmail(user?.email)
	}, [user])

	const handleDarkMode = () => {
		// TODO
	}

	const handleEmailChange = () => {
		if (user?.email === newEmail) return
		// check if email is valid
		if (!newEmail.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
			toast.error("Invalid email")
			return
		}

		changeEmail(newEmail)
			.then((msg) => {
				toast(msg)
			})
			.catch((err) => {
				console.log(err.message)
				toast.error(err.message)
			})
	}

	const handlePasswordChange = () => {
		if (password === "" || confirmPassword === "") {
			return
		} else if (password !== confirmPassword) {
			toast.error("Passwords do not match")
			return
		} else if (password.length < 6) {
			toast.error("Password should be at least 6 characters")
			return
		}

		changePassword(password)
			.then((msg) => {
				toast(msg)
				setPassword("")
				setConfirmPassword("")
			})
			.catch((err) => {
				console.log(err.message)
				toast.error(err.message)
			})
	}

	return (
		<div className="settings-page flex flex-col w-full h-full p-4 gap-6">
			<h1 className="text-2xl font-bold w-full text-left">Account</h1>
			<div className="flex flex-col gap-6 w-2/3">
				{/* TODO */}
				<div className="flex flex-row justify-between items-center">
					<label htmlFor="dark-mode">Dark Mode</label>
					<Switch defaultChecked={true} disabled />
				</div>
				<div className="flex flex-row justify-between items-center">
					<span className="font-bold">Email</span>
					<div className="flex gap-2">
						<input
							className="px-2 py-1 border border-gray-300 rounded-md text-black"
							type="email"
							placeholder="Email"
							value={newEmail}
							onChange={(e) => setNewEmail(e.target.value)}
						/>
						<button
							className={`${
								user?.email === newEmail
									? "bg-theme-800 hover:bg-theme-700"
									: "bg-orange-500 hover:bg-orange-400"
							} text-white rounded-md px-2 py-1 w-20 `}
							onClick={handleEmailChange}
						>
							change
						</button>
						<button
							className={`${
								user?.email === newEmail ? "" : ""
							} bg-theme-800 hover:bg-theme-700 text-white rounded-md px-2 py-1 w-10 `}
							onClick={() => {
								setNewEmail(user?.email)
							}}
						>
							<FontAwesomeIcon icon={faRefresh} />
						</button>
					</div>
				</div>
				<div className="flex flex-col gap-2">
					<div className="flex flex-row justify-between items-center">
						<span className="font-bold">New Password</span>
						<div className="flex gap-2">
							<input
								className="px-2 py-1 border border-gray-300 rounded-md text-black"
								type="password"
								placeholder="Password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<div className="w-32" />
						</div>
					</div>
					<div className="flex flex-row justify-between items-center">
						<div />
						<div className="flex gap-2">
							<input
								className="px-2 py-1 border border-gray-300 rounded-md text-black"
								type="password"
								placeholder="Confirm"
								value={confirmPassword}
								onChange={(e) => setConfirmPassword(e.target.value)}
								onKeyDown={(e) => {
									if (e.key === "Enter") {
										handlePasswordChange()
									}
								}}
							/>
							<button
								className={`${
									password == "" || confirmPassword == ""
										? "bg-theme-800 hover:bg-theme-700"
										: "bg-orange-500 hover:bg-orange-400"
								} text-white rounded-md px-2 py-1 w-20 `}
								onClick={handlePasswordChange}
							>
								change
							</button>
							<button
								className={`${
									password === "" || confirmPassword == "" ? "opacity-0" : ""
								} bg-theme-800 hover:bg-theme-700 text-white rounded-md px-2 py-1 w-10 `}
								onClick={() => {
									setPassword("")
									setConfirmPassword("")
								}}
							>
								<FontAwesomeIcon icon={faRefresh} />
							</button>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-2">
					<TagSettings />
				</div>
				<div className="flex flex-col gap-2">
					<InstagramSettings />
				</div>
				{/* <div className="flex flex-col justify-start items-start gap-4">
					<button
						className="flex flex-row gap-2 items-center justify-start bg-instagram-400 hover:bg-instagram-300 p-4 rounded-md"
						onClick={() => {
							getInstagramUserDetails(user?.id)
						}}
					>
						Download Instagram User Details
					</button>
				</div> */}
			</div>
		</div>
	)
}

export default SettingsPage
