import { getDocs, addDoc, updateDoc, query, where, collection, DocumentData, DocumentReference, documentId, or } from "firebase/firestore"
import { db, storage } from "../firebaseConfig"
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import { Filters, Image, Tag } from "../../types"
import store, { setImage as setStoreImage, setImages, deleteImage as deleteStoreImage } from '../../stores/store'
import getAllImages from "./getAllImages"
import deleteImage from "./deleteImage"
import getAllIgAccounts from "./getAllIgAccounts"
import startImageUpload from "./startImageUpload"
import setImage from "./setImage"
import updateImage from "./updateImage"

const initApp = async (refresh: boolean = false) => {
    return Promise.all([
        getAllImages(refresh),
        getAllIgAccounts(refresh)
    ])
}

export {
    getAllImages,
    deleteImage,
    setImage,
    getAllIgAccounts,
    startImageUpload,
    updateImage,
    initApp,
}