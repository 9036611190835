import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faTasks,
	faFileAlt,
	faCalendar,
	faExchangeAlt,
	faUpload,
	faCog,
	faHome,
	faSignOut,
} from "@fortawesome/free-solid-svg-icons"
import { Link, useLocation } from "react-router-dom"

type SidebarItemProps = {
	href: string
	icon: any
	text: string
}

const SidebarItem: React.FC<SidebarItemProps> = ({ href, icon, text }) => {
	const location = useLocation()

	return (
		<li>
			<Link
				to={href}
				className={`hover:bg-theme-800 w-full rounded-md p-2 text-nowrap ${
					location.pathname === href ? "bg-theme-800" : ""
				}`}
			>
				<FontAwesomeIcon icon={icon} /> {text}
			</Link>
		</li>
	)
}

const Sidebar = () => {
	return (
		<div className="h-screen p-4">
			<div id="sidebar-card" className="bg-theme-850 p-4 rounded-xl">
				<ul className="space-y-2 text-left">
					<SidebarItem href="/home" icon={faHome} text="Home" />
					<SidebarItem href="/assets" icon={faFileAlt} text="Assets" />
					<SidebarItem href="/assign" icon={faTasks} text="Assign" />
					{/* <SidebarItem href="/generate" icon={faFileAlt} text="Generate" /> */}
					<SidebarItem href="/schedule" icon={faCalendar} text="Schedule" />
					<SidebarItem
						href="/face-swap"
						icon={faExchangeAlt}
						text="Face Swap"
					/>
					<SidebarItem href="/upload" icon={faUpload} text="Upload" />
					<SidebarItem href="/settings" icon={faCog} text="Settings" />
					<SidebarItem href="/logout" icon={faSignOut} text="Logout" />
				</ul>
			</div>
		</div>
	)
}

export default Sidebar
