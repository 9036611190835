import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    getDocs,
    addDoc,
    updateDoc,
    query,
    where,
    collection,
    doc,
    DocumentData,
    DocumentReference
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { Tag } from "../types";
import { create } from "domain";
import { time } from "console";

export const tagsApi = createApi({
    reducerPath: 'tags',
    baseQuery: fakeBaseQuery(),
    tagTypes: [ 'Tags' ],
    endpoints: (builder) => ({
        getTags: builder.query<Tag[], string>({
            queryFn: async (userId: string) => {
                try {
                    const q = query(
                        collection(db, `users/${userId}/tags`),
                        // where("finishedUploading", "==", true),
                        where("deleted", "==", false)
                    );
                    const querySnapshot = await getDocs(q);
                    const tags = querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    })) as Tag[];
                    return { data: tags };
                } catch (error) {
                    console.error("error getting tags", error)
                    return { error: { status: 'FETCH_ERROR', error: String(error) } };
                }
            },
            providesTags: [ 'Tags' ],
        }),
        addTag: builder.mutation<{ id: string }, { userId: string, tag: Omit<Tag, 'id'> }>({
            queryFn: async ({ userId, tag }) => {
                try {
                    const newTag = {
                        ...{
                            timeCreated: new Date().getTime(),
                            deleted: false,
                            color: null,
                        },
                        ...tag,
                    }
                    const docRef = await addDoc(collection(db, `users/${userId}/tags`), newTag);
                    return { data: { id: docRef.id } };
                } catch (error) {
                    return { error: { status: 'FETCH_ERROR', error: String(error) } };
                }
            },
            async onQueryStarted({ userId, tag }, { dispatch, queryFulfilled }) {
                const tempId = 'temp-' + new Date().getTime();
                const newTag = {
                    ...{
                        timeCreated: new Date().getTime(),
                        deleted: false,
                        color: null,
                    },
                    ...tag,
                }
                const patchResult = dispatch(
                    tagsApi.util.updateQueryData('getTags', userId, (draft) => {
                        draft.push({
                            id: tempId,
                            ...newTag,
                        })
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
            invalidatesTags: [ 'Tags' ],
        }),
        updateTag: builder.mutation<void, { userId: string, tagId: string, updates: Partial<Tag> }>({
            queryFn: async ({ userId, tagId, updates }) => {
                try {
                    const tagRef = doc(db, `users/${userId}/tags`, tagId);
                    await updateDoc(tagRef, updates);
                    return { data: undefined };
                } catch (error) {
                    return { error: { status: 'FETCH_ERROR', error: String(error) } };
                }
            },
            async onQueryStarted({ userId, tagId, updates }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    tagsApi.util.updateQueryData('getTags', userId, (draft) => {
                        const tagToUpdate = draft.find(tag => tag.id === tagId)
                        if (tagToUpdate) {
                            Object.assign(tagToUpdate, updates)
                        }
                    })
                )
                try {
                    await queryFulfilled
                } catch {
                    patchResult.undo()
                }
            },
            invalidatesTags: [ 'Tags' ],
        }),
    }),
});

export const {
    useGetTagsQuery,
    useAddTagMutation,
    useUpdateTagMutation,
} = tagsApi;