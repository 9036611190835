import React from "react"
import { useState } from "react"
import { faX } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { startImageUpload } from "../utils/firebase/api/api"

const UploadPage = () => {
	const [files, setFiles] = useState<File[]>([] as File[])
	const [progress, setProgress] = useState<number[]>([])

	const handleChange = (event) => {
		setFiles((prevFiles) => [
			...prevFiles,
			...(Array.from(event.target.files) as File[]),
		])
		setProgress((prevProgress) => [
			...prevProgress,
			...new Array(event.target.files.length).fill(0),
		])
	}

	const handleUpload = () => {
		if (!files.length) {
			alert("No files selected")
			return
		}

		files.forEach(async (file, index) => {
			if (progress[index] === -1) return

			startImageUpload(file, (percent) =>
				setProgress((prevProgress) => {
					const newProgress = [...prevProgress]
					newProgress[index] = percent
					return newProgress
				})
			)
		})
	}

	return (
		<div className="w-full h-full overflow-y-scroll scrollbar scrollbar-thin scrollbar-thumb-theme-300 scrollbar-thumb-rounded-full scrollbar-track-theme-900">
			<div className="">
				<div className="flex items-center justify-center w-full mt-2 mb-4 gap-4">
					<input
						type="file"
						accept="image/*"
						onChange={handleChange}
						multiple
						className="py-2 px-4 border border-gray-300 rounded-md"
					/>
					<button
						onClick={handleUpload}
						className="py-2 px-4 bg-blue-500 text-white rounded-md bg-theme-500 hover:bg-theme-600"
					>
						Start Upload
					</button>
				</div>
				{files.map((file, index) => (
					<div
						key={index}
						className="flex items-center justify-between w-full mb-2 gap-2 mr-4"
					>
						<img
							src={URL.createObjectURL(file)}
							alt="Preview"
							className="w-16 h-16"
						/>
						<span className="w-full text-left">{file.name}</span>
						{progress[index] !== -1 ? (
							<>
								<div className="flex items-center w-1/2 h-5 rounded-full bg-theme-950">
									<div
										style={{ width: `${progress[index]}%` }}
										className="h-full bg-theme-500 rounded-full"
									></div>
								</div>
								<span
									className={
										progress[index] == 0 ? "ml-2 text-gray-500" : "ml-2"
									}
								>
									{progress[index]}%
								</span>
								<button className="py-2 px-4 text-gray-500 rounded-md">
									<FontAwesomeIcon icon={faX} />
								</button>
							</>
						) : (
							<span className="ml-2">Done</span>
						)}
					</div>
				))}
			</div>
		</div>
	)
}
export default UploadPage
