import React, { useEffect } from "react"
import { Tag } from "../utils/types"
import { useAppSelector } from "../utils/hooks"
import { setImage } from "../utils/firebase/api/api"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faX } from "@fortawesome/free-solid-svg-icons"
import Tooltip from "./tooltip"
import { on } from "events"

interface TagSelectProps {
	onTagSelect: (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		tagId: string,
		curTags: string[]
	) => void
	onTagRemove: (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		tagId: string,
		curTags: string[]
	) => void
	onClear: (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		curTags: string[]
	) => void
	allTags: Tag[]
	selectedTags: string[]
}

const MAX_TAG_NAME_COUNT_LENGTH = 20

export const TagSelect: React.FC<TagSelectProps> = ({
	onTagSelect,
	onTagRemove,
	onClear,
	allTags,
	selectedTags,
}) => {
	const [curTagIds, setCurTagIds] = React.useState(selectedTags)
	const [showUnselected, setShowUnselected] = React.useState(false)

	const truncate = (str: string, n: number) => {
		return str?.length > n ? str.substr(0, n - 1) + "..." : str
	}

	return (
		<div className="text-lg font-bold p-2 flex flex-col rounded-md gap-2">
			<span>Tags</span>
			<div
				className="flex flex-col border-theme-800 border-2 rounded-md w-full justify-start p-1"
				onMouseEnter={() => setShowUnselected(true)}
				onMouseLeave={() => setShowUnselected(false)}
			>
				{!allTags || allTags.length === 0 ? (
					<div className="ml-2 text-sm text-gray-500">
						Go to{" "}
						<a href="/settings" className="underline">
							settings
						</a>{" "}
						to add tags
					</div>
				) : (
					<div className="flex flex-row gap-2 items-start justify-between w-full">
						<div className="text-sm flex flex-wrap gap-2">
							{curTagIds.map((tagId, index) => (
								<button
									key={index}
									className={`flex flex-row gap-2 hover:bg-theme-800 p-2 rounded-md items-center bg-theme group ${
										allTags.find((t) => t.id == tagId)?.color || "bg-theme-800"
									}`}
									onClick={(e) => {
										setCurTagIds(curTagIds.filter((t) => t !== tagId))
										onTagRemove(e, tagId, curTagIds)
									}}
								>
									<span className="whitespace-nowrap">
										{truncate(
											allTags.find((t) => t.id == tagId)?.name,
											MAX_TAG_NAME_COUNT_LENGTH
										)}
									</span>
									<FontAwesomeIcon
										icon={faX}
										className="group-hover:text-red-500"
									/>
								</button>
							))}
						</div>
						<Tooltip text="Clear">
							<button
								className="flex flex-row gap-2 hover:text-red-500 p-2 rounded-md items-start justify-start text-gray-500"
								onClick={(e) => {
									onClear(e, curTagIds)
									setCurTagIds([])
								}}
							>
								<FontAwesomeIcon icon={faX} />
							</button>
						</Tooltip>
					</div>
				)}
				{allTags.filter((tag) => !curTagIds?.includes(tag.id)).length !== 0 && (
					<div
						className="overflow-y-scroll text-sm flex flex-col gap-2 transition-height duration-300 ease-in-out scrollbar-thin scrollbar-thumb-theme-300 scrollbar-thumb-rounded-full scrollbar-track-theme-900"
						style={{
							maxHeight: showUnselected ? "300px" : "0",
							marginTop: showUnselected ? "0.5rem" : "0",
						}}
					>
						{allTags
							.filter((tag) => !curTagIds?.includes(tag.id))
							.map((tag, index) => (
								<button
									key={index}
									className={`flex flex-row gap-2 hover:bg-theme-800 p-2 rounded-md items-center ${
										curTagIds?.includes(tag.id) ? "bg-theme-800" : ""
									}`}
									onClick={(e) => {
										setCurTagIds([...curTagIds, tag.id])
										onTagSelect(e, tag.id, curTagIds)
									}}
								>
									<FontAwesomeIcon icon={faPlus} />
									<span className="whitespace-nowrap">
										{truncate(tag.name, MAX_TAG_NAME_COUNT_LENGTH)}
									</span>
								</button>
							))}
					</div>
				)}
			</div>
		</div>
	)
}

export default TagSelect
