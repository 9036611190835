import { getDocs, addDoc, updateDoc, query, where, collection, DocumentData, DocumentReference, documentId, or } from "firebase/firestore"
import store, { setImage as setStoreImage, setImages, deleteImage as deleteStoreImage } from '../../stores/store'
import { db, storage } from "../firebaseConfig"
import { Image } from "../../types"

export default async (id: string) => {
    if (store.getState() === undefined || store.getState().root.user === undefined) return
    const userId = store.getState().root.user.id

    const tagQ = query(
        collection(db, `/users/${userId}/tags`),
        where("deleted", "==", false)
    )

    const [ tagQuerySnapshot, imageDoc ] = await Promise.all([
        getDocs(tagQ),
        getDocs(query(
            collection(db, `users/${userId}/images`),
            where(documentId(), "==", id)
        ))
    ]);

    const tagRefs = tagQuerySnapshot.docs.map((doc) => doc.ref)
    const tagIds = tagRefs.map((tag) => tag.id)

    if (!imageDoc.docs.length) return

    const cleanImage = {
        ...store.getState().root.images.find((i) => i.id === id),
        ...imageDoc.docs[ 0 ].data(),
        tags: imageDoc.docs[ 0 ].data()?.tags.map((tag: DocumentReference) => tag.id).filter(tagId => tagId !== undefined && tagIds.includes(tagId)) || [],
    }

    store.dispatch(setStoreImage(cleanImage))
}