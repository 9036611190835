import React, { useEffect } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FaCaretDown } from "react-icons/fa"
import { useAppDispatch, useAppSelector } from "../../../utils/hooks"
import { getAllImages, getAllIgAccounts } from "../../../utils/firebase/api/api"
import { setFilters } from "../../../utils/stores/store"
import { faUser } from "@fortawesome/free-solid-svg-icons"

interface IgAccountDropdownOption {
	id: string
	name: string
	checked: boolean
	igAccount?: boolean
}

interface IgAccountDropdownProps {}

const IgAccountDropdown: React.FC<IgAccountDropdownProps> = () => {
	const dispatch = useAppDispatch()
	const igAccounts = useAppSelector((state) => state.root.igAccounts)
	const filters = useAppSelector((state) => state.root.filters)
	const [isOpen, setIsOpen] = React.useState(true)

	useEffect(() => {
		getAllIgAccounts()
	}, [])

	return (
		<div
			className="text-lg font-bold cursor-pointer p-2 flex flex-col rounded-md"
			onClick={() => setIsOpen(!isOpen)}
		>
			<div className="flex flex-row items-center justify-between w-full">
				<span>Instagram Accounts</span>
				<FaCaretDown
					size={25}
					style={{
						transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
						transition: "transform 0.3s ease-in-out",
					}}
				/>
			</div>
			<div
				className="overflow-hidden flex flex-col gap-2"
				style={{
					transition: "max-height 0.3s ease-in-out",
					marginTop: isOpen ? "0.5rem" : "0",
					maxHeight: isOpen ? "300px" : "0",
				}}
			>
				{igAccounts.length === 0 ? (
					<div className="ml-2 text-sm text-gray-500">
						Go to{" "}
						<a href="/settings" className="underline">
							settings
						</a>{" "}
						to add Instagram accounts
					</div>
				) : (
					igAccounts.map((igAccount, index) => (
						<div
							key={index}
							className={`flex flex-row gap-2 hover:bg-theme-800 rounded-md p-2 items-center cursor-pointer justify-start cursor-pointer ${
								filters.igAccounts.includes(igAccount.id) ? "bg-theme-800" : ""
							}`}
							onClick={(e) => {
								if (filters.igAccounts.includes(igAccount.id)) {
									dispatch(
										setFilters({
											...filters,
											igAccounts: filters.igAccounts.filter(
												(id) => id !== igAccount.id
											),
										})
									)
								} else {
									dispatch(
										setFilters({
											...filters,
											igAccounts: Array.from(
												new Set([...filters.igAccounts, igAccount.id])
											),
										})
									)
								}

								getAllImages(true)

								e.stopPropagation()
							}}
						>
							{igAccount.profileImageUrl && (
								<img
									src={igAccount.profileImageUrl}
									alt="profile"
									className="w-8 h-8 rounded-full"
								/>
							)}
							<span className="whitespace-nowrap">{igAccount.username}</span>
						</div>
					))
				)}
				<div
					key="uncategorized"
					className={`flex flex-row gap-2 hover:bg-theme-800 rounded-md p-2 items-center cursor-pointer justify-start cursor-pointer ${
						filters.igAccounts.includes("uncategorized") ? "bg-theme-800" : ""
					}`}
					onClick={(e) => {
						if (filters.igAccounts.includes("uncategorized")) {
							dispatch(
								setFilters({
									...filters,
									igAccounts: filters.igAccounts.filter(
										(id) => id !== "uncategorized"
									),
								})
							)
						} else {
							dispatch(
								setFilters({
									...filters,
									igAccounts: Array.from(
										new Set([...filters.igAccounts, "uncategorized"])
									),
								})
							)
						}

						getAllImages(true)

						e.stopPropagation()
					}}
				>
					<div className="w-8 h-8">
						<FontAwesomeIcon icon={faUser} />
					</div>
					<span className="whitespace-nowrap">Uncategorized</span>
				</div>
			</div>
		</div>
	)
}

export default IgAccountDropdown
