import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInstagram } from "@fortawesome/free-brands-svg-icons"
import { facebookOathLink } from "../../utils/firebase/facebook"
import { useAppDispatch, useAppSelector } from "../../utils/hooks"
import { faPlus, faUser, faX } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify"

const InstagramSettings = () => {
	const dispatch = useAppDispatch()
	const user = useAppSelector((state) => state.root.user)
	const igAccounts = useAppSelector((state) => state.root.igAccounts)
	const loginWithInstagram = () => {
		// console.log(facebookOathLink())
		window.location.href = facebookOathLink()
	}

	return (
		<div className="flex flex-col justify-start items-start gap-4">
			{igAccounts.map((igAccount, index) => (
				<div
					key={index}
					className="flex flex-row gap-2 items-center justify-start border border-instagram-400 p-4 rounded-xl cursor-pointer"
					onClick={() => {}}
				>
					{igAccount.profileImageUrl ? (
						<img
							src={igAccount.profileImageUrl}
							alt="profile"
							className="w-8 h-8 rounded-full"
						/>
					) : (
						<div className="w-8 h-8 rounded-full flex items-center justify-center">
							<FontAwesomeIcon icon={faUser} size="lg" />
						</div>
					)}
					<span className="whitespace-nowrap">{igAccount.username}</span>
					<div className="hover:text-red-400">
						<FontAwesomeIcon icon={faX} />
					</div>
				</div>
			))}
			<button
				className="flex flex-row gap-2 items-center justify-start bg-instagram-400 hover:bg-instagram-300 p-4 rounded-md"
				onClick={loginWithInstagram}
			>
				<FontAwesomeIcon icon={faInstagram} />
				Connect new Instagram account
			</button>
		</div>
	)
}

export default InstagramSettings
