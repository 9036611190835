import React, { useState } from "react"
import TextArea from "../../../components/textArea"
import { setUserSettings } from "../../../utils/stores/store"
import { useAppDispatch, useAppSelector } from "../../../utils/hooks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faX } from "@fortawesome/free-solid-svg-icons"

interface EditGlobalPromptModalProps {
	isOpen: boolean
	onClose: () => void
}

const EditGlobalPromptModal: React.FC<EditGlobalPromptModalProps> = ({
	isOpen,
	onClose,
}) => {
	const dispatch = useAppDispatch()
	const globalPrompt = useAppSelector(
		(state) => state.root.userSettings.globalPrompt
	)
	const [globalPromptText, setGlobalPromptText] = React.useState(globalPrompt)

	return isOpen ? (
		<div
			className={`fixed flex-col gap-4 top-0 left-0 w-full h-full bg-theme-900 bg-opacity-90 flex items-center justify-center cursor-pointer z-30 ${
				isOpen ? "" : "hidden"
			}`}
			onClick={onClose}
		>
			<div
				className="w-1/2 flex flex-col gap-2 items-start justify-center bg-theme-850 p-4 rounded-xl cursor-default pointer-events-auto"
				onClick={(e) => e.stopPropagation()}
			>
				<div className="flex flex-row justify-between w-full items-center">
					<h1 className="text-2xl font-bold">Edit global prompt</h1>
					<button
						className="hover:text-red-500 cursor-pointer text-gray-500 p-4 py-2"
						onClick={onClose}
					>
						<FontAwesomeIcon icon={faX} size="xl" />
					</button>
				</div>
				<TextArea
					id="global-prompt"
					className="w-full"
					placeholder={"Enter global prompt here..."}
					value={globalPromptText}
					resize={false}
					alwaysMaxHeight={true}
					onChangeQuick={(e) => setGlobalPromptText(e.target.value)}
					onChangeDebounced={(e) => {
						dispatch(setUserSettings({ globalPrompt: e.target.value }))
					}}
				/>
			</div>
		</div>
	) : null
}

export default EditGlobalPromptModal
