import { getDocs, addDoc, updateDoc, query, where, collection, DocumentData, DocumentReference, documentId, or } from "firebase/firestore"
import store, { setImage as setStoreImage, setImages, deleteImage as deleteStoreImage, setIgAccounts } from '../../stores/store'
import { db, storage } from "../firebaseConfig"
import { IgAccount, Tag } from "../../types"
import { getDownloadURL, ref } from "firebase/storage"


export default async (refresh = false): Promise<IgAccount[]> => {
    if (store.getState() === undefined || store.getState().root.user === undefined) return
    if (store.getState().root.igAccountsLoaded && !refresh) {
        return store.getState().root.igAccounts
    }
    const userId = store.getState().root.user.id

    const q = query(
        collection(db, `/users/${userId}/igAccounts`),
        where("deleted", "==", false)
    )

    return getDocs(q).then(async (querySnapshot) => {

        const igAccounts = await Promise.all(querySnapshot.docs.map(async (doc) => {
            // get profile image url
            let profileImageUrl = undefined
            try {
                profileImageUrl = await getDownloadURL(ref(storage, `igProfilePhotos/${doc.id}`))
            } catch (e) {
                profileImageUrl = undefined
            }

            return {
                id: doc.id,
                ...doc.data(),
                profileImageUrl,
            } as IgAccount
        }))

        store.dispatch(setIgAccounts(igAccounts))

        console.log("got igAccounts", igAccounts)

        return igAccounts
    }).catch((e) => {
        console.error("error getting igAccounts: ", e)
        return []
    })
}