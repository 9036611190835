import { getDocs, addDoc, updateDoc, query, where, collection, DocumentData, DocumentReference, documentId, or } from "firebase/firestore"
import store, { setImage as setStoreImage, setImages, deleteImage as deleteStoreImage } from '../../stores/store'
import { db, storage } from "../firebaseConfig"
import { Image } from "../../types"

export default async (image: Image) => {
    if (store.getState() === undefined || store.getState().root.user === undefined) return
    const userId = store.getState().root.user.id

    const imageDoc = await getDocs(query(
        collection(db, `users/${userId}/images`),
        where(documentId(), "==", image.id)
    ))

    if (!imageDoc.docs.length) return

    const cleanImage = { ...store.getState().root.images.find((i) => i.id === image.id), ...image }

    // check if the tags have changed and change to references
    const imageDocTags = imageDoc.docs[ 0 ].data().tags.map((tag: DocumentReference) => tag.id)
    if (image.tags && image.tags !== imageDocTags) {
        const tagRefs = await getDocs(
            collection(db, `/users/${userId}/tags`)
        ).then((querySnapshot) => querySnapshot.docs.map((doc) => doc.ref))

        image.tags = image.tags
            .map((tagId) => tagRefs.find((tag) => tag.id === tagId))
            .filter((tag) => tag !== undefined);
    }

    store.dispatch(setStoreImage(cleanImage))

    return updateDoc(imageDoc.docs[ 0 ].ref, {
        ...store.getState().root.images.find((i) => i.id === image.id),
        ...image
    })
}