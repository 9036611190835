// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { connectStorageEmulator, getStorage } from "firebase/storage"
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore"
import { connectFunctionsEmulator, getFunctions, } from 'firebase/functions'
import { connectAuthEmulator, getAuth } from "firebase/auth"
import { defineString } from "firebase-functions/params"

// Your web app's Firebase configuration
// const firebaseConfig = {
//     apiKey: defineString('FIREBASE_API_KEY').value(),
//     authDomain: defineString('FIREBASE_AUTH_DOMAIN').value(),
//     projectId: defineString('FIREBASE_PROJECT_ID').value(),
//     storageBucket: defineString('FIREBASE_STORAGE_BUCKET').value(),
//     messagingSenderId: defineString('FIREBASE_MESSAGING_SENDER_ID').value(),
//     appId: defineString('FIREBASE_APP_ID').value()
// }
const firebaseConfig = {
    apiKey: "AIzaSyCeyYbcNO876Ag8HXKfI-da2GAvTwEaQJ0",
    authDomain: "model-manager-00.firebaseapp.com",
    projectId: "model-manager-00",
    storageBucket: "model-manager-00.appspot.com",
    messagingSenderId: "351430183159",
    appId: "1:351430183159:web:2c9a000bfcd7c27c466bf2"
}



// Initialize Firebase
const app = initializeApp(firebaseConfig)
const storage = getStorage(app)
const db = getFirestore(app)
const functions = getFunctions(app)
const auth = getAuth(app)

if (process.env.NODE_ENV === 'development') {
    connectFunctionsEmulator(functions, "localhost", 5001)
    connectStorageEmulator(storage, "localhost", 9199)
    connectFirestoreEmulator(db, "localhost", 8080)
    connectAuthEmulator(auth, "http://localhost:9099")
}


export { app, storage, db, functions, auth }