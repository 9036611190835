import { PayloadAction, combineReducers, configureStore, createSlice } from '@reduxjs/toolkit'
import { Image, Tag, Filters, User, IgAccount } from '../types'
import { assetsApi } from './assets'
import { tagsApi } from './tags'
import { get } from 'http'
import type { Middleware } from '@reduxjs/toolkit'

const rootSlice = createSlice({
    name: 'root',
    initialState: {
        images: [] as Image[], // deprecated
        imagesLoaded: false as boolean, // deprecated
        selectedImage: null as Image | null, // deprecated
        igAccounts: [] as IgAccount[],
        igAccountsLoaded: false as boolean,
        filters: {
            tags: [],
            igAccounts: [],
        } as Filters,
        user: undefined as User,
        userSettings: {
            size: 3 as number,
            globalPrompt: "Caption this instagram post as an online model. Use multiple hashtags at the end and use emoji's conservatively." as string,
        },
    },
    reducers: {
        setImages: (state, action: PayloadAction<Image[]>) => {
            state.images = action.payload
            state.imagesLoaded = true
        },
        deleteImage: (state, action: PayloadAction<string>) => {
            state.images = state.images.filter((i) => i.id !== action.payload)
        },
        setSelectedImage: (state, action: PayloadAction<string>) => {
            state.selectedImage = state.images.find(i => i.id === action.payload)
        },
        setIgAccounts: (state, action: PayloadAction<IgAccount[]>) => {
            state.igAccounts = action.payload
            state.igAccountsLoaded = true
        },
        setImage(state, action: PayloadAction<Image>) {
            state.images = state.images.map((i) => {
                if (i.id === action.payload.id) {
                    return { ...i, ...action.payload }
                }
                return i
            })

            if (state.selectedImage?.id === action.payload.id) {
                state.selectedImage = state.images.find(i => i.id === action.payload.id)
            }
        },
        setFilters(state, action: PayloadAction<{ tags: string[], igAccounts: string[] }>) {
            state.filters = action.payload
        },
        setUserSettings(state, action: PayloadAction<{ size?: number, globalPrompt?: string }>) {
            state.userSettings = {
                ...state.userSettings,
                ...action.payload,
            }

        },
        setUser(state, action: PayloadAction<User | undefined>) {
            if (action.payload === undefined) {
                state.user = undefined
            } else {
                state.user = {
                    ...state.user,
                    ...action.payload
                }
            }
        }
    },
})

const rootReducer = combineReducers({
    [ assetsApi.reducerPath ]: assetsApi.reducer,
    [ tagsApi.reducerPath ]: tagsApi.reducer,
    root: rootSlice.reducer
})

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(assetsApi.middleware as Middleware)
            .concat(tagsApi.middleware as Middleware),
})

export const {
    setImages,
    setImage,
    deleteImage,
    setSelectedImage,
    setIgAccounts,
    setFilters,
    setUserSettings,
    setUser,
} = rootSlice.actions

export default store

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>