import React from "react"
import { useEffect } from "react"
import { useAppSelector, useAppDispatch } from "../../../utils/hooks"
import { getAllImages, getAllIgAccounts } from "../../../utils/firebase/api/api"
import { setSelectedImage } from "../../../utils/stores/store"
import CaptionEdit from "./captionEdit"
import CaptionGeneration from "./captionGeneration"
import Sidebar from "./sidebar"

const ImageSettings = () => {
	const dispatch = useAppDispatch()
	const igAccounts = useAppSelector((state) => state.root.igAccounts)
	const selectedImage = useAppSelector((state) => state.root.selectedImage)
	const [mode, setMode] = React.useState<"main" | "caption">("main")
	const [selectedImageCaption, setSelectedImageCaption] = React.useState(
		selectedImage.caption
	)

	useEffect(() => {
		getAllIgAccounts()
	}, [])

	return (
		<div
			className={`fixed flex-col gap-4 top-0 left-0 w-full h-full bg-theme-900 bg-opacity-90 flex items-between justify-center cursor-pointer z-10`}
			onClick={() => {
				dispatch(setSelectedImage(null))
				getAllImages(true)
			}}
		>
			<div
				className={`h-2/3 object-cover cursor-default aspect-1 mt-4 flex items-center justify-center pointer-events-none`}
			>
				<img
					className=" h-full object-cover aspect-1 pointer-events-auto"
					src={selectedImage.url}
					alt="selected"
					onClick={(e) => e.stopPropagation()}
				/>
			</div>
			{mode === "main" ? (
				<CaptionEdit setMode={setMode} />
			) : (
				<CaptionGeneration setMode={setMode} />
			)}
			<div
				className="fixed right-0 top-0 p-4 z-20 w-80 pointer-events-auto cursor-default"
				onClick={(e) => e.stopPropagation()}
			>
				<Sidebar />
				{mode === "main" && (
					<a
						href={selectedImage.url}
						className="fixed left-0 bottom-0 z-20 ml-4 mb-6"
					>
						{selectedImage.id}
					</a>
				)}
			</div>
		</div>
	)
}

export default ImageSettings
