import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    getDocs,
    addDoc,
    updateDoc,
    query,
    where,
    collection,
    doc,
    DocumentData,
    DocumentReference
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { Asset } from "../types";

export const assetsApi = createApi({
    reducerPath: 'assets',
    baseQuery: fakeBaseQuery(),
    tagTypes: [ 'Assets' ],
    endpoints: (builder) => ({
        getAssets: builder.query<Asset[], string>({
            queryFn: async (userId: string) => {
                try {
                    console.log("getAssets")
                    const q = query(
                        collection(db, `users/${userId}/assets`),
                        // where("finishedUploading", "==", true),
                        where("deleted", "==", false)
                    );
                    const querySnapshot = await getDocs(q);
                    const assets = querySnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    })) as Asset[];
                    return { data: assets };
                } catch (error) {
                    console.error("error getting assets", error)
                    return { error: { status: 'FETCH_ERROR', error: String(error) } };
                }
            },
            providesTags: [ 'Assets' ],
        }),
        addAsset: builder.mutation<{ id: string }, { userId: string, asset: Omit<Asset, 'id'> }>({
            queryFn: async ({ userId, asset }) => {
                try {
                    const docRef = await addDoc(collection(db, `users/${userId}/assets`), asset);
                    return { data: { id: docRef.id } };
                } catch (error) {
                    return { error: { status: 'FETCH_ERROR', error: String(error) } };
                }
            },
            invalidatesTags: [ 'Assets' ],
        }),
        updateAsset: builder.mutation<void, { userId: string, assetId: string, updates: Partial<Asset> }>({
            queryFn: async ({ userId, assetId, updates }) => {
                try {
                    const assetRef = doc(db, `users/${userId}/assets`, assetId);
                    await updateDoc(assetRef, updates);
                    return { data: undefined };
                } catch (error) {
                    return { error: { status: 'FETCH_ERROR', error: String(error) } };
                }
            },
            invalidatesTags: [ 'Assets' ],
        }),
    }),
});

export const {
    useGetAssetsQuery,
    useAddAssetMutation,
    useUpdateAssetMutation
} = assetsApi;