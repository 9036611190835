import React, { useEffect } from "react"
import Sidebar from "./components/sidebar"
import { Outlet, useNavigate } from "react-router-dom"
import { onAuthStateChanged } from "firebase/auth"
import store, { setUser } from "./utils/stores/store"
import { useDispatch } from "react-redux"
import { auth } from "./utils/firebase/firebaseConfig"
import { getAllImages, initApp } from "./utils/firebase/api/api"

const WithSidebar = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	return (
		<>
			<Sidebar />
			<Outlet />
		</>
	)
}

export default WithSidebar
