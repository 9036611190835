import { faCheck, faEllipsis } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../utils/hooks"
import { setImage } from "../../../utils/firebase/api/api"
import { Image } from "../../../utils/types"
import TextArea from "../../../components/textArea"
import { select } from "firebase-functions/params"
import { generateCaptions } from "../../../utils/firebase/functions"

interface CaptionEditProps {
	setMode: (mode: "main" | "caption") => void
}

const CaptionEdit = ({ setMode }: CaptionEditProps) => {
	const dispatch = useAppDispatch()
	const selectedImage = useAppSelector((state) => state.root.selectedImage)
	const captionOptions = useAppSelector(
		(state) => state.root.selectedImage.generatedCaptions
	)
	const [caption, setCaption] = React.useState(selectedImage.caption || "")

	useEffect(() => {
		setCaption(selectedImage.caption || "")
	}, [selectedImage])

	return (
		<div
			className="h-full w-full flex items-start justify-center gap-4 p-4 pointer-events-auto cursor-default"
			onClick={(e) => e.stopPropagation()}
		>
			<div className="relative w-2/3 h-1/3 flex flex-col gap-4">
				<TextArea
					textAreaClassName="min-h-32"
					placeholder="Enter image caption here..."
					resize={true}
					value={caption}
					onChangeDebounced={(e) =>
						setImage({ id: selectedImage.id, caption: e.target.value })
					}
					onChangeQuick={(e) => setCaption(e.target.value)}
				/>
				<div className="flex flex-row items-center justify-end w-full min-h-6">
					<button
						className="flex items-center justify-center cursor-pointer p-2 px-4 rounded-md hover:bg-theme-800 bg-theme-850 font-bold hover:text-lg shimmer"
						onClick={() => {
							setMode("caption")

							if (
								selectedImage.captionsLoading ||
								(captionOptions && captionOptions.length == 4)
							)
								return

							setImage({
								id: selectedImage.id,
								captionsLoading: true,
							})
							generateCaptions(selectedImage.id)
						}}
					>
						<span>Generate ✨</span>
					</button>
				</div>
			</div>
		</div>
	)
}

export default CaptionEdit
