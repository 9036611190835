import { getDocs, addDoc, updateDoc, query, where, collection, DocumentData, DocumentReference, documentId, or } from "firebase/firestore"
import store, { setImage as setStoreImage, setImages, deleteImage as deleteStoreImage } from '../../stores/store'
import { db, storage } from "../firebaseConfig"
import { Image } from "../../types"

export default async (refresh = false): Promise<Image[]> => {
    if (store.getState() === undefined || store.getState().root.user === undefined) return
    if (store.getState().root.imagesLoaded && !refresh) {
        return store.getState().root.images
    }
    const userId = store.getState().root.user.id

    const tagQ = query(
        collection(db, `/users/${userId}/tags`),
        where("deleted", "==", false)
    )

    const tagQuerySnapshot = await getDocs(tagQ)
    const tagRefs = tagQuerySnapshot.docs.map((doc) => doc.ref)
    // const IgAccountQuerySnapshot = await getDocs(collection(db, "models"))
    // const modelRefs = modelQuerySnapshot.docs.map((doc) => doc.ref)

    const richFilters = {
        tags: tagRefs.filter((tag) => store.getState().root.filters.tags.includes(tag.id)),
        // models: modelRefs.filter((model) => store.getState().filters.models.includes(model.id)),
    }

    let q = query(
        collection(db, `users/${userId}/images`),
        where("downloaded", "==", true),
        where("deleted", "==", false)
    )

    if (richFilters.tags.length > 0) {
        q = query(q, where("tags", "array-contains-any", richFilters.tags))
    }

    // if (richFilters.models.length > 0) {
    //     if (store.getState().filters.models.includes('uncategorized')) {
    //         q = query(q,
    //             where("models", "==", []),
    //         )
    //     }

    //     q = query(q, where("models", "array-contains-any", richFilters.models))
    // }

    const tagIds = tagRefs.map((tag) => tag.id)
    return getDocs(q).then((querySnapshot) => {
        const images = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            tags: doc.data().tags?.map((tag: DocumentReference) => tag.id)
                .filter((tagId: string) => tagId !== undefined && tagIds.includes(tagId)) || [],
        })) as Image[]
        store.dispatch(setImages(images))
        return images
    }).catch((e) => {
        console.error("error getting images: ", e)
        return []
    })
}