import { faPlus, faX } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useAppSelector } from "../../utils/hooks"
import { toast } from "react-toastify"
import {
	useAddTagMutation,
	useGetTagsQuery,
	useUpdateTagMutation,
} from "../../utils/stores/tags"
import { Tag } from "../../utils/types"

const TagSettings = () => {
	const dispatch = useDispatch()
	const user = useAppSelector((state) => state.root.user)
	const [newTagName, setNewTagName] = React.useState("")
	const {
		data: tags,
		isLoading,
		isError,
	} = useGetTagsQuery(user.id || "", { refetchOnMountOrArgChange: true })
	const [updateTag] = useUpdateTagMutation()
	const [createTag] = useAddTagMutation()

	const handleCreateTag = async (name: string) => {
		createTag({
			userId: user.id,
			tag: {
				name,
			},
		}).then(({ data }) => {
			toast(`${name} created!`)
		})
		setNewTagName("")
	}

	const deleteTag = async (tag: Tag) => {
		updateTag({
			userId: user.id,
			tagId: tag.id,
			updates: {
				deleted: true,
			},
		})
		toast(`${tag.name} deleted!`)
	}

	return (
		<div className="flex flex-col justify-start items-center gap-4">
			<h1 className="text-2xl font-bold w-full text-left">Tags</h1>
			<div className="flex gap-2 w-full">
				<input
					className="px-2 py-1 border border-gray-300 rounded-md text-black w-full text-right"
					type="email"
					placeholder="New tag name"
					value={newTagName}
					onChange={(e) => setNewTagName(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							handleCreateTag(newTagName)
						}
					}}
				/>
				<button
					className="min-w-20 bg-theme-800 flex items-center justify-center rounded-md hover:bg-theme-700"
					onClick={() => handleCreateTag(newTagName)}
				>
					<FontAwesomeIcon icon={faPlus} />
				</button>
			</div>
			<div className="flex flex-row flex-wrap gap-2 w-full border-4 rounded-lg border-theme-800 bg-theme-850 p-4">
				{tags && tags.length !== 0 ? (
					<div className="text-sm flex flex-wrap gap-2">
						{[...tags]
							.sort((a, b) => (a.timeCreated || 0) - (b.timeCreated || 0))
							.filter((tag) => !tag.deleted)
							.map((tag, index) => (
								<button
									key={index}
									className={`flex flex-row gap-2 hover:bg-theme-800 p-2 rounded-md items-center bg-theme group ${
										tag.color || "bg-theme-800"
									}`}
									onClick={() => {
										deleteTag(tag)
									}}
								>
									<span className="whitespace-nowrap">{tag.name}</span>
									<FontAwesomeIcon
										icon={faX}
										className="group-hover:text-red-500"
									/>
								</button>
							))}
					</div>
				) : (
					<span className="w-full text-theme-400 text-right">
						create some tags to get started
					</span>
				)}
			</div>
		</div>
	)
}

export default TagSettings
