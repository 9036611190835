import { addDoc, collection, doc, setDoc, updateDoc } from "firebase/firestore"
import store from '../../stores/store'
import { auth, db, storage } from "../firebaseConfig"
import { initApp } from "./api"
import { updateEmail, updatePassword } from "firebase/auth";
import getAllImages from "./getAllImages"
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"

export default async (file: File, stateChanged: (percent: number) => void) => {
    if (store.getState() === undefined || store.getState().root.user === undefined) return
    const userId = store.getState().root.user.id

    const newDoc = await addDoc(collection(db, `users/${userId}/images`), {
        tags: [],
        models: [],
        downloaded: false,
        deleted: false,
        url: "",
        userId: store.getState().root.user.id,
    })


    const storageRef = ref(storage, `images/${newDoc.id}`)
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const percent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            )

            stateChanged(percent)
        },
        (err) => console.error(err),
        async () => {
            stateChanged(-1)

            await updateDoc(newDoc, {
                url: await getDownloadURL(storageRef),
                downloaded: true,
            })

            getAllImages(true)
        }
    )
}