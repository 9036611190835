import React, { ReactElement } from "react"

type TooltipProps = {
	text: string
	children: ReactElement<any, any>
}

const Tooltip: React.FC<TooltipProps> = ({ text, children }) => {
	const [show, setShow] = React.useState(false)

	return (
		<div className="flex justify-center">
			<div
				role="tooltip"
				className={`tooltip absolute text-sm duration-300 bg-theme-950 p-1 px-2 rounded-md ${
					show ? "opacity-100" : "opacity-0"
				}`}
				style={{
					transform: `translate(0%, -100%)`,
				}}
			>
				{text}
			</div>
			<div
				onMouseEnter={() => setShow(true)}
				onMouseLeave={() => setShow(false)}
			>
				{children}
			</div>
		</div>
	)
}

export default Tooltip
