import React from "react"
import { useEffect } from "react"
import { useAppSelector, useAppDispatch } from "../../../utils/hooks"
import { getAllImages, getAllIgAccounts } from "../../../utils/firebase/api/api"
import { setFilters } from "../../../utils/stores/store"
import IgAccountDropdown from "./igAccountDropdown"
import TagSelect from "../../../components/tagSelect"
import { useGetTagsQuery } from "../../../utils/stores/tags"

const Filters = () => {
	const dispatch = useAppDispatch()
	const user = useAppSelector((state) => state.root.user)
	const { data: tags, isLoading, isError } = useGetTagsQuery(user.id || "")
	const filters = useAppSelector((state) => state.root.filters)

	useEffect(() => {
		// getAllTags()
	}, [])

	return (
		<div className="h-screen p-4 ">
			<div id="sidebar-card" className="bg-theme-850 p-2 rounded-xl w-80">
				<div className="text-left flex flex-col">
					<span className="text-2xl font-bold px-2">Filters</span>
					<IgAccountDropdown />
					<TagSelect
						allTags={tags}
						selectedTags={filters.tags}
						onTagSelect={(_, tag, curTags) => {
							dispatch(
								setFilters({
									...filters,
									tags: Array.from(new Set([...curTags, tag])),
								})
							)
							getAllImages(true)
						}}
						onTagRemove={(_, tag, curTags) => {
							dispatch(
								setFilters({
									...filters,
									tags: curTags.filter((t) => t !== tag),
								})
							)
							getAllImages(true)
						}}
						onClear={() => {
							dispatch(
								setFilters({
									...filters,
									tags: [],
								})
							)
							getAllImages(true)
						}}
					/>
				</div>
			</div>
		</div>
	)
}

export default Filters
