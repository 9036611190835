import { faCheck, faEllipsis } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect } from "react"

type TextAreaProps = {
	placeholder: string
	debounce?: number
	value: string
	className?: string
	textAreaClassName?: string
	resize?: boolean
	onChangeQuick: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
	onChangeDebounced: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
	onSubmit?: () => void
	alwaysMaxHeight?: boolean
	id?: string
}

const TextArea: React.FC<TextAreaProps> = ({
	placeholder,
	debounce = 500,
	value,
	onChangeQuick,
	onChangeDebounced,
	onSubmit,
	resize = false,
	className = "",
	textAreaClassName = "",
	alwaysMaxHeight = false,
	id,
}) => {
	const [changeTimeout, setChangeTimeout] = React.useState(null)
	const [changing, setChanging] = React.useState(false)
	const [isSelected, setIsSelected] = React.useState(false)

	useEffect(() => {
		if (!id && alwaysMaxHeight)
			throw new Error("TextArea component requires an id prop when max height")

		if (alwaysMaxHeight) {
			const target = document.getElementById(id)
			if (!target) return
			target.style.height = target.scrollHeight + "px"
		}
	}, [value])

	return (
		<div className={`flex flex-col font-bold px-2 relative ${className}`}>
			<textarea
				id={id}
				className={`p-3 rounded-xl bg-theme-850 border-theme-800 border-4 overflow-hidden resize-none h-full ${textAreaClassName} ${
					!resize &&
					!alwaysMaxHeight &&
					"overflow-y-scroll scrollbar-thin scrollbar-thumb-theme-300 scrollbar-thumb-rounded-full scrollbar-track-theme-900"
				}`}
				value={value}
				onKeyDown={(e) => {
					if (e.key === "Enter" && e.ctrlKey) {
						onSubmit()
					}
				}}
				placeholder={placeholder}
				onMouseEnter={(e) => {
					if (!resize) return

					const target = e.target as HTMLTextAreaElement
					target.style.height = target.scrollHeight + "px"
				}}
				onMouseLeave={(e) => {
					if (isSelected || !resize) return

					const target = e.target as HTMLTextAreaElement
					target.style.height = "auto"
				}}
				onFocus={(e) => {
					setIsSelected(true)
					if (!resize) return

					const target = e.target as HTMLTextAreaElement
					target.style.height = target.scrollHeight + "px"
				}}
				onBlur={(e) => {
					setIsSelected(false)
					if (!resize) return

					const target = e.target as HTMLTextAreaElement
					target.style.height = "auto"
				}}
				onChange={(e) => {
					if (changeTimeout) {
						clearTimeout(changeTimeout)
					}

					setChanging(true)
					onChangeQuick(e)

					setChangeTimeout(
						setTimeout(() => {
							onChangeDebounced(e)
							setChanging(false)
						}, debounce)
					)

					if (!resize && !alwaysMaxHeight) return

					const target = e.target as HTMLTextAreaElement
					target.style.height = target.scrollHeight + "px"
				}}
			/>
			{changing ? (
				<FontAwesomeIcon
					icon={faEllipsis}
					className="absolute right-4 bottom-2 text-gray-500"
				/>
			) : (
				<FontAwesomeIcon
					icon={faCheck}
					className="absolute right-4 bottom-2 text-gray-500"
				/>
			)}
		</div>
	)
}

export default TextArea
