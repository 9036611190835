import React from "react"
import { Provider } from "react-redux"
import { Outlet } from "react-router-dom"
import store from "./utils/stores/store"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const Root = () => {
	return (
		<Provider store={store}>
			<ToastContainer theme="dark" closeOnClick hideProgressBar />
			<div className="App flex flex-row bg-theme-900 h-screen text-theme-50">
				<Outlet />
			</div>
		</Provider>
	)
}

export default Root
