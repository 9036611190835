import { addDoc, collection, doc, setDoc } from "firebase/firestore"
import store, { setImages, setIgAccounts, setUser } from "../../stores/store"
import { auth, db } from "../firebaseConfig"
import { initApp } from "./api"
import { updateEmail, updatePassword } from "firebase/auth";


const signUpUser = async (id: string, email: string) => {
    store.dispatch(
        setUser({
            id: id,
            email: email,
        })
    )

    return setDoc(doc(db, "users", id), {
        email: email,
        emailVerified: false,
    })
}

const loginUser = async (id: string, email: string) => {
    store.dispatch(setImages([]))
    store.dispatch(setIgAccounts([]))

    store.dispatch(
        setUser({
            id,
            email,
            emailVerified: false
        })
    )

    initApp(true)
}

const changeEmail = async (email: string) => {
    if (store.getState() === undefined || store.getState().root.user === undefined) return
    const userId = store.getState().root.user.id


    try {
        await updateEmail(auth.currentUser, email);

        setDoc(doc(db, "users", userId), {
            email: email,
        })

        store.dispatch(
            setUser({
                id: userId,
                email: email,
            })
        )
        return "Email updated successfully"
    } catch (error) {
        switch (error.code) {
            case "auth/email-already-in-use":
                throw new Error("Email already in use!")
        }
    }
}

const changePassword = async (password: string) => {
    if (store.getState() === undefined || store.getState().root.user === undefined) return
    const userId = store.getState().root.user.id

    try {
        await updatePassword(auth.currentUser, password);

        return "Password updated successfully"
    } catch (error) {
        switch (error.code) {
            case "auth/weak-password":
                throw new Error("The password is too weak. Password should be at least 6 characters")
        }
    }
}

export {
    signUpUser,
    loginUser,
    changeEmail,
    changePassword
}