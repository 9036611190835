import { LoaderFunctionArgs, redirect } from "react-router-dom"
import objectToParams from "../../helpers/objectToParams"
import { connectInstagramUser } from "./functions"
import { auth } from "./firebaseConfig"

const facebookOathLink = () => {
    // return `https://www.facebook.com/dialog/oauth${objectToParams({
    //     client_id: process.env.REACT_APP_FACEBOOK_APP_ID,
    //     redirect_uri: window.location.origin + "/instagram-callback",
    //     scope: "public_profile,email,instagram_basic,pages_show_list",
    //     response_type: "code",
    //     auth_type: "rerequest",
    //     config_id: process.env.REACT_APP_FACEBOOK_CONFIG_ID,
    // })}`
    console.log("redirectUri", window.location.origin + "/instagram-callback")
    return `https://api.instagram.com/oauth/authorize${objectToParams({
        client_id: "472273591880453",
        redirect_uri: window.location.origin + "/instagram-callback",
        response_type: "code",
        scope: "user_profile,user_media",
        // config_id: process.env.REACT_APP_FACEBOOK_CONFIG_ID,
    })}`
}

const handleFacebookCallback = async (response: LoaderFunctionArgs<any>, userId: string): Promise<"success" | "error"> => {
    const code = new URL(response.request.url).searchParams.get("code")
    const redirectUri = window.location.origin + "/instagram-callback"

    return connectInstagramUser(userId, code, redirectUri)
}

export { facebookOathLink, handleFacebookCallback }