import { createUserWithEmailAndPassword } from "firebase/auth"
import React, { useState } from "react"
import { auth } from "../utils/firebase/firebaseConfig"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { signUpUser } from "../utils/firebase/api/auth"
import { ToastContainer, toast } from "react-toastify"

const SignUpPage = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [confirmPassword, setConfirmPassword] = useState("")
	const [emailError, setEmailError] = useState("")
	const [passwordError, setPasswordError] = useState("")
	const [confirmPasswordError, setConfirmPasswordError] = useState("")

	const handleSignUp = () => {
		// check if passwords match
		if (password !== confirmPassword) {
			setConfirmPasswordError("Passwords do not match")
			return
		}

		setEmailError("")
		setPasswordError("")
		setConfirmPasswordError("")

		createUserWithEmailAndPassword(auth, email, password)
			.then((userCredential) =>
				signUpUser(userCredential.user.uid, userCredential.user.email)
			)
			.then(() => {
				setTimeout(() => toast("Account created!"), 1000)
				navigate("/login")
			})
			.catch((error) => {
				const errorCode = error.code
				const errorMessage = error.message
				console.log(errorCode, errorMessage)

				if (errorCode === "auth/weak-password") {
					setPasswordError(
						"The password is too weak. Password should be at least 6 characters"
					)
				} else if (errorCode === "auth/email-already-in-use") {
					setEmailError("The email is already in use")
				} else if (errorCode === "auth/invalid-email") {
					setEmailError("The email is invalid")
				} else if (errorCode === "auth/missing-password") {
					setPasswordError("The password is required")
				} else if (errorCode === "auth/missing-email") {
					setEmailError("The email is required")
				} else {
					setEmailError("")
					setPasswordError("")
					setConfirmPasswordError("")
				}
			})
	}

	return (
		<div className="container mx-auto flex flex-col items-center justify-center">
			<h1 className="text-4xl font-bold mb-8">Welcome</h1>
			<div className="flex flex-col space-y-2">
				<input
					className="px-4 py-2 border border-gray-300 rounded-md text-theme-950"
					type="email"
					placeholder="Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				{emailError && (
					<span className="text-sm text-red-400">{emailError}</span>
				)}
				<input
					className="px-4 py-2 border border-gray-300 rounded-md text-theme-950"
					type="password"
					placeholder="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							handleSignUp()
						}
					}}
				/>
				{passwordError && (
					<span className="text-sm text-red-400">{passwordError}</span>
				)}
				<input
					className="px-4 py-2 border border-gray-300 rounded-md text-theme-950"
					type="password"
					placeholder="Confirm Password"
					value={confirmPassword}
					onChange={(e) => setConfirmPassword(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							handleSignUp()
						}
					}}
				/>
				{confirmPasswordError && (
					<span className="text-sm text-red-400">{confirmPasswordError}</span>
				)}
				<button
					onClick={handleSignUp}
					className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-300 font-bold"
				>
					Sign Up
				</button>
				<span>
					Already have an account?{" "}
					<Link
						to="/login"
						className="px-4 py-2 rounded-md font-bold text-center hover:underline"
					>
						Login
					</Link>
				</span>
			</div>
		</div>
	)
}

export default SignUpPage
