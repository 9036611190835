import * as React from "react"
import * as ReactDOM from "react-dom/client"
import {
	createBrowserRouter,
	RouterProvider,
	redirect,
	LoaderFunctionArgs,
} from "react-router-dom"
import "./index.css"
import App from "./withSidebar"
import HomePage from "./routes/home"
import AssetsPage from "./routes/assets"
import UploadPage from "./routes/upload"
import LoginPage from "./routes/login"
import SignUpPage from "./routes/signUp"
import Root from "./root"
import WithSidebar from "./withSidebar"
import { onAuthStateChanged, signOut } from "firebase/auth"
import { auth } from "./utils/firebase/firebaseConfig"
import store, { setUser } from "./utils/stores/store"
import SettingsPage from "./routes/settings"
import { handleFacebookCallback } from "./utils/firebase/facebook"
import { toast } from "react-toastify"
import getAllIgAccounts from "./utils/firebase/api/getAllIgAccounts"

const getUser = async (): Promise<
	{ id: string; email: string; emailVerified: boolean } | undefined
> => {
	return new Promise((resolve) => {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				store.dispatch(
					setUser({
						id: user.uid,
						email: user.email,
						emailVerified: user.emailVerified,
					})
				)
				resolve({
					id: user.uid,
					email: user.email,
					emailVerified: user.emailVerified,
				})
			} else {
				store.dispatch(setUser(undefined))
				resolve(undefined)
			}
		})
	})
}

const rootElement = document.getElementById("root")
const router = createBrowserRouter([
	{
		path: "/",
		element: <Root />,
		children: [
			{
				path: "/",
				loader: async () => {
					const user = await getUser()
					if (user !== undefined) {
						return redirect("/home")
					}

					return null
				},
				children: [
					{
						path: "/login",
						element: <LoginPage />,
					},
					{
						path: "/sign-up",
						element: <SignUpPage />,
					},
					{
						path: "/",
						loader: async () => {
							return redirect("/login")
						},
					},
				],
			},
			{
				path: "/",
				loader: async () => {
					const user = await getUser()
					if (user === undefined) {
						return redirect("/login")
					}
					return null
				},
				element: <WithSidebar />,
				children: [
					{
						path: "logout",
						loader: async () => {
							signOut(auth)
							store.dispatch(setUser(undefined))
							return redirect("/login")
						},
					},
					{
						path: "home",
						element: <HomePage />,
					},
					{
						path: "assets",
						element: <AssetsPage />,
					},
					{
						path: "upload",
						element: <UploadPage />,
					},
					{
						path: "settings",
						element: <SettingsPage />,
					},
					{
						path: "instagram-callback",
						loader: async (request) => {
							const user = await getUser()
							if (user === undefined) {
								setTimeout(
									() => toast("You must be signed in to connect!"),
									1000
								)
								return redirect("/login")
							}
							setTimeout(() => toast("Talking to Instagram..."), 1000)
							handleFacebookCallback(request, user.id).then((response) => {
								if (response === "success") {
									toast("Successfully connected ${instagramAccount.name}!")
									getAllIgAccounts(true)
									// TODO add loading to state
								} else {
									setTimeout(
										() => toast.error("Error connecting account!"),
										1000
									)
								}
							})
							return redirect("/settings")
						},
					},
					{
						path: "/",
						loader: async () => {
							return redirect("/home")
						},
					},
				],
			},
		],
	},
])

if (rootElement) {
	ReactDOM.createRoot(rootElement).render(
		<React.StrictMode>
			<RouterProvider router={router} />
		</React.StrictMode>
	)
}
