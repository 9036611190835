import { getDocs, addDoc, updateDoc, query, where, collection, DocumentData, DocumentReference, documentId, or } from "firebase/firestore"
import store, { setImage as setStoreImage, setImages, deleteImage as deleteStoreImage } from '../../stores/store'
import { db, storage } from "../firebaseConfig"
import { Image } from "../../types"

export default async (id: string) => {
    if (store.getState() === undefined || store.getState().root.user === undefined) return
    const userId = store.getState().root.user.id

    getDocs(query(
        collection(db, `users/${userId}/images`),
        where(documentId(), "==", id)
    )).then((querySnapshot) =>
        querySnapshot.docs.map((doc) => {
            updateDoc(doc.ref, {
                deleted: true,
            })
        })
    )

    store.dispatch(deleteStoreImage(id))
}