import React, { useEffect } from "react"
import { Switch } from "@mui/material"
import TagSelect from "../../../components/tagSelect"
import { useAppDispatch, useAppSelector } from "../../../utils/hooks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons"
import { deleteImage } from "../../../utils/firebase/api/api"
import { setSelectedImage } from "../../../utils/stores/store"
import { setImage } from "../../../utils/firebase/api/api"
import { useGetTagsQuery } from "../../../utils/stores/tags"

const Sidebar = () => {
	const dispatch = useAppDispatch()
	const selectedImage = useAppSelector((state) => state.root.selectedImage)
	const user = useAppSelector((state) => state.root.user)
	const { data: tags, isLoading, isError } = useGetTagsQuery(user.id || "")

	const onClickDelete = () => {
		deleteImage(selectedImage.id)
		dispatch(setSelectedImage(null))
	}

	return (
		<div id="sidebar-card" className="bg-theme-850 p-2 rounded-xl">
			<div className="text-left flex flex-col gap-2">
				<span className="text-2xl font-bold px-2">Settings</span>
				<TagSelect
					onTagSelect={(_, id, curTags) => {
						setImage({
							id: selectedImage.id,
							tags: Array.from(new Set([...curTags, id])),
						})
					}}
					onTagRemove={(_, id, curTags) => {
						setImage({
							id: selectedImage.id,
							tags: curTags.filter((tag) => tag !== id),
						})
					}}
					onClear={() => {
						setImage({ id: selectedImage.id, tags: [] })
					}}
					allTags={tags}
					selectedTags={selectedImage.tags}
				/>
				<div className="flex flex-row justify-start items-center">
					<Switch
						checked={
							selectedImage.switches?.filter((s) => s.name === "nsfw").length >
							0
						}
						onChange={(e) =>
							setImage({
								id: selectedImage.id,
								switches:
									selectedImage.switches.filter((s) => s.name === "nsfw")
										.length !== 0
										? selectedImage.switches.filter((s) => s.name !== "nsfw")
										: [...selectedImage.switches],
							})
						}
					/>
					<span className="text-l font-bold px-2">Posted</span>
				</div>
				<a
					className="flex flex-row gap-2 justify-between text-2xl font-bold px-2 cursor-pointer hover:bg-theme-800 rounded-md p-2 items-center"
					href={selectedImage.url}
					download={`${selectedImage.id}.jpg`}
				>
					<span>Download</span>
					<FontAwesomeIcon icon={faDownload} />
				</a>
				<button
					onClick={onClickDelete}
					className="flex flex-row gap-2 justify-between text-2xl font-bold px-2 cursor-pointer hover:bg-theme-800 rounded-md p-2 text-red-500 items-center"
				>
					<span>Delete</span>
					<FontAwesomeIcon icon={faTrash} />
				</button>
			</div>
		</div>
	)
}

export default Sidebar
