import React, { useEffect, useState } from "react"
import { auth } from "../utils/firebase/firebaseConfig"
import "firebase/auth"
import { Link, redirect, useLocation, useNavigate } from "react-router-dom"
import {
	Auth,
	browserLocalPersistence,
	browserSessionPersistence,
	onAuthStateChanged,
	setPersistence,
	signInWithEmailAndPassword,
} from "firebase/auth"
import { useDispatch } from "react-redux"
import { loginUser } from "../utils/firebase/api/auth"
import { toast } from "react-toastify"

const LoginPage = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [emailError, setEmailError] = useState("")
	const [passwordError, setPasswordError] = useState("")
	const [staySignedIn, setStaySignedIn] = useState(false)

	const handleLogin = () => {
		setEmailError("")
		setPasswordError("")

		const persistence = staySignedIn
			? browserLocalPersistence
			: browserSessionPersistence
		setPersistence(auth, persistence).then(() => {
			signInWithEmailAndPassword(auth, email, password)
				.then((userCredential) => {
					loginUser(userCredential.user.uid, userCredential.user.email)
					setTimeout(() => toast("Successfully logged in!"), 1000)
					navigate("/home")
				})
				.catch((error) => {
					const errorCode = error.code
					const errorMessage = error.message
					// console.log(errorCode, errorMessage)
					switch (errorCode) {
						case "auth/invalid-credential":
							setPasswordError("Email or password is incorrect")
							break
						case "auth/wrong-password":
							setPasswordError("The password is invalid")
							break
						case "auth/user-not-found":
							setEmailError("The email is not registered")
							break
						case "auth/invalid-email":
							setEmailError("The email is invalid")
							break
						case "auth/missing-password":
							setPasswordError("The password is required")
							break
						case "auth/missing-email":
							setEmailError("The email is required")
							break
						default:
							setEmailError("")
							setPasswordError("")
					}
				})
		})
	}

	return (
		<div className="container mx-auto flex flex-col items-center justify-center">
			<h1 className="text-4xl font-bold mb-8">Login Page</h1>
			<div className="flex flex-col space-y-2">
				<input
					className="px-4 py-2 border border-gray-300 rounded-md text-theme-950"
					type="email"
					placeholder="Email"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				{emailError && (
					<span className="text-sm text-red-400">{emailError}</span>
				)}
				<input
					className="px-4 py-2 border border-gray-300 rounded-md text-theme-950"
					type="password"
					placeholder="Password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							handleLogin()
						}
					}}
				/>
				{passwordError && (
					<span className="text-sm text-red-400">{passwordError}</span>
				)}
				<div className="flex flex-row justify-start gap-2">
					<input
						type="checkbox"
						checked={staySignedIn}
						onChange={(e) => setStaySignedIn(e.target.checked)}
					/>
					<label htmlFor="staySignedIn">Stay Signed In</label>
				</div>
				<button
					className="px-4 py-2 bg-blue-500 text-white rounded-md bg-theme-800 font-bold hover:bg-theme-600"
					onClick={handleLogin}
				>
					Login
				</button>
				<span>
					Need an account?{" "}
					<Link
						to="/sign-up"
						className="px-4 py-2 rounded-md font-bold text-center hover:underline"
					>
						SIGN UP
					</Link>
				</span>
			</div>
		</div>
	)
}

export default LoginPage
function loginWithEmailAndPassword(
	auth: Auth,
	email: string,
	password: string
) {
	throw new Error("Function not implemented.")
}
